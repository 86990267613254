import "core-js/stable";
import "regenerator-runtime/runtime";
import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import styled, { ThemeProvider } from "styled-components";
import { Normalize } from "styled-normalize";
import { v4 as uuidv4 } from "uuid";
import {
  setTerms,
  setTermsType,
  setAnalysis,
  setAnalysisType,
} from "../../actions/terms";
import { IFont } from "../../interfaces/IFontInterface";
import { ITermsList } from "../../interfaces/ITermsInterface";
import theme from "../../styles/theme";
import BaseStyles from "../../styles/baseStyles";
import AppLoading from "../../components/AppLoading";
import Header from "../../components/Header";
import HomeScreen from "../HomeScreen";
import Writer from "../Writer";
import Analyzing from "../Analyzing";
import TermScreen from "../TermScreen";
import ExperimentalScreen from "../../Experimental";
import SettingsScreen from "../SettingsScreen";
import TermNotFound from "../../components/TermNotFound";
import BottomBar from "../../components/BottomBar";
import Login from "../Login";
import ClioScreen from "../Clio";
import { initialState } from "../../reducers/user";
import server from "../../../utilities/server/index.js";
import {
  IUser,
  LICENSE_TYPES,
  ANALYSIS_ENGINE,
} from "../../interfaces/IUserInterface";
import {
  setUser,
  setUserType,
  setLicenseType,
  setLicenseTypeType,
  setAdmin,
  setAdminType,
  setSubscriptionOwner,
  setSubscriptionOwnerType,
  setAccessTokenType,
  setAccessToken,
} from "../../actions/user";
import { setIsGraphDialog } from "../../actions/application";
import TermsLoading from "../../components/TermsLoading";
import LicenseError from "../../components/LicenseError";
import NoLicense from "../../components/NoLicense";
import { loadTheme, createTheme } from "office-ui-fabric-react";
import axios from "axios";
var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
import { environment_conf } from "../../../utilities/environment";
import { Authenticator } from "../../../utilities/office.helpers.js";
import "react-toastify/dist/ReactToastify.css";

export interface IAppProps {
  title: string;
  isOfficeInitialized: boolean;
  setTerms: setTermsType;
  setAnalysis: setAnalysisType;
  terms: ITermsList;
  font: IFont;
  setLicense: setLicenseTypeType;
  setAdmin: setAdminType;
  setUser: setUserType;
  user: IUser;
  setSubscriptionOwner: setSubscriptionOwnerType;
  setAccessToken: setAccessTokenType;
}

export interface IAppState {
  licenseFetchingStatus?: string;
  provider?: string;
  errorMessage?: string;
  authenticator?: Authenticator;
  scrollTerm?: ITerm;
  definitions?: {
    id: string;
    word: string;
    definition: string;
    // nestedDefinitionId: string,
  }[];
  user: IUser;
  environment_conf: object;
}

const AppStylesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  justify-content: space-between;
  font-size: ${(props) => parseInt("1") + props.fontSize + "em"};
  font-family: ${(props) => props.fontFamily};
  height: 100vh;
  width: 100vw;
`;

const FontStylesContainer = styled.div`
  font-size: ${(props) => parseInt("1") + props.fontSize + "em"};
  font-family: ${(props) => props.fontFamily};
`;

class App extends React.Component<IAppProps, IAppState> {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loggingIn: false,
      provider: "",
      licenseFetchingStatus: "notFetched",
      definitions: [],
      scrollTerm: undefined,
      user: Object.assign({}, initialState),
      environment_conf: environment_conf,
    };

    var fontSize = parseInt("1") + this.props.font.size + "em";
    var fontFamily = this.props.font.family;

    loadTheme(
      createTheme({
        defaultFontStyle: { fontFamily: fontFamily },
        fonts: {
          medium: { fontSize: fontSize },
        },
      })
    );

    this.boundSetState = this.setState.bind(this);
    this.setToken = this.setToken.bind(this);
    this.displayError = this.displayError.bind(this);
    this.login = this.login.bind(this);
    this.getLicense = this.getLicense.bind(this);
    this.navToSpectacles = this.navToSpectacles.bind(this);
    this.logout = this.logout.bind(this);
    this.handleGraphClick = this.handleGraphClick.bind(this);
    this.loginWithSpectacles = this.loginWithSpectacles.bind(this);
    this.cancelLogin = this.cancelLogin.bind(this);
    this.setAsLoggedIn = this.setAsLoggedIn.bind(this);
    this.setAsLoggedOut = this.setAsLoggedOut.bind(this);
    this.refreshClauses = this.refreshClauses.bind(this);
    this.getMode = this.getMode.bind(this);

    // console.log('App Props: ', this.props);
  }

  componentDidMount() {
    const authenticator = new Authenticator();

    authenticator.endpoints.registerMicrosoftAuth(
      "b1d523f9-3a6d-49be-ae57-b9ce8ddbe881",
      {
        scope: "openid email profile",
        responseType: "id_token",
        nonce: true,
      }
    );

    authenticator.endpoints.registerGoogleAuth(
      "266185792476-6rh7v1ese99chhqda8850stucb1l6s0p.apps.googleusercontent.com",
      {
        scope: "openid email profile",
        responseType: "id_token",
        nonce: true,
      }
    );

    authenticator.endpoints.add("Okta", {
      name: "Okta",
      clientId: environment_conf.okta_client_id,
      baseUrl:
        "https://spectaclesb2c.b2clogin.com/spectaclesb2c.onmicrosoft.com/oauth2/v2.0",
      authorizeUrl: "/authorize",
      responseType: "id_token",
      scope: "openid profile",
      extraQueryParameters: {
        p: "B2C_1_Okta",
      },
      nonce: true,
      //usePKCE: true,
    });

    authenticator.endpoints.add("Clio", {
      name: "Clio",
      clientId: "EHUnlJcgucIZOVzRkmdruqCeHAzyjsBj56XTZkUM",
      baseUrl: environment_conf.base_app_domain,
      authorizeUrl: "/Clio/Login",
      responseType: "id_token",
    });

    this.setState({ authenticator: authenticator });

    if (Office.context.isDialog) {
      Office.context.ui.addHandlerAsync(
        Office.EventType.DialogParentMessageReceived,
        (args) => {
          const user = JSON.parse(args.message).user;
          const analysis = JSON.parse(args.message).analysis;

          this.props.setIsGraphDialog(true);
          this.props.setUser(user);
          this.props.setAnalysis(analysis);
        }
      );

      Office.context.ui.messageParent("ready");
    }
  }

  /*
       Methods
   */

  handleGraphClick = () => {
    let dialog;
    let self = this;

    Office.context.ui.displayDialogAsync(
      environment_conf.base_manifest_domain,
      function (asyncResult) {
        dialog = asyncResult.value;
        dialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          (message) => {
            const json = JSON.stringify({
              user: self.props.user,
              analysis: self.props.analysis,
            });
            dialog.messageChild(json);
          }
        );
      }
    );
  };

  boundSetState: () => {};

  navToSpectacles = () => {
    window.open(environment_conf.base_app_domain);
  };

  cancelLogin = () => {
    this.setState({ loggingIn: false });
  };

  setToken = (accesstoken: string) => {
    this.props.setAccessToken(accesstoken);
    this.getLicense(accesstoken);
  };

  displayError = (error: string) => {
    this.setState({ errorMessage: error });
  };

  setAsLoggedIn(provider) {
    var user = this.state.user;

    user.authStatus = "loggedIn";
    user.provider = provider;
    user.loggedIn = true;

    this.props.setUser(user);
  }

  setAsLoggedOut() {
    this.props.setUser(Object.assign({}, initialState));
  }

  login = async (
    provider: string,
    extraParams: { [index: string]: string }
  ) => {
    if (provider == "Spectacles") {
      this.setState({ loggingIn: true });
    } else {
      var self = this;
      this.state.authenticator
        .authenticate(
          provider,
          true,
          false,
          environment_conf.base_manifest_domain + "/redirecter/redirecter.html",
          extraParams
        )
        .then(function (token) {
          self.setAsLoggedIn(provider);
          self.setToken(token.id_token || token.code);
        })
        .catch((e) => {
          console.log(">>>>>>>>>>>>> ERROR LOG FROM LOGIN: " + e)
          self.displayError(e.toString());
          console.log(JSON.stringify(e));
        });
    }
  };

  loginWithSpectacles = async (username: string, password: string) => {
    var self = this;
    self.setState({ errorMessage: null });
    axios
      .post(environment_conf.base_app_domain + "/Account/Login_App", {
        Username: username,
        Password: password,
      })
      .then(function (response) {
        self.setAsLoggedIn("Spectacles");
        self.setToken(response.data);
      })
      .catch(function (error) {
        self.displayError(error.toString());
        console.log(error);
      });
  };

  logout = async () => {
    // Clearing chat history
    localStorage.removeItem("message_history")
    
    this.state.authenticator.tokens.clear();
    this.setAsLoggedOut();
  };

  getLicense = async (accessToken) => {
    var resp = await server.getLicense(accessToken);
    const user = {
      name: resp.data.username,
      email: resp.data.username,
      loggedIn: true,
      licenseType:
        resp.data.planId.toUpperCase() === "ADMIN"
          ? LICENSE_TYPES["PREMIUM"]
          : LICENSE_TYPES[resp.data.planId.toUpperCase()],
      admin: resp.data.planId.toUpperCase() === "ADMIN",
      subscriptionOwner: resp.data.subscriptionOwner,
      analysisEngine: ANALYSIS_ENGINE.REGEX,
    };

    this.props.setUser(user);
  };

  refreshClauses = async () => {
    this.props.setAnalysis(null);
  };

  getMode = () => {
    return this.props.history.location.pathname === "/writer"
      ? "write"
      : "read";
  };

  render() {
    const { title, isOfficeInitialized } = this.props;

    if (!isOfficeInitialized) {
      return (
        <FontStylesContainer
          fontSize={this.props.font.size}
          fontFamily={this.props.font.family}
        >
          <ThemeProvider theme={theme}>
            <AppLoading />
          </ThemeProvider>
        </FontStylesContainer>
      );
    }

    if (this.props.user.authStatus === "notLoggedIn") {
      var environment_p;
      if (!environment_conf.isProduction)
        environment_p = <p>{environment_conf.environment}</p>;

      return (
        <FontStylesContainer
          fontSize={this.props.font.size}
          fontFamily={this.props.font.family}
        >
          <ThemeProvider theme={theme}>
            {environment_p}

            <Login
              login={this.login}
              navToSpectacles={this.navToSpectacles}
              errorMessage={this.state.errorMessage}
              loggingIn={this.state.loggingIn}
              loginWithSpectacles={this.loginWithSpectacles}
              cancelLogin={this.cancelLogin}
            ></Login>
          </ThemeProvider>
        </FontStylesContainer>
      );
    } else if (this.props.user.authStatus === "loginInProcess") {
      return (
        <FontStylesContainer
          fontSize={this.props.font.size}
          fontFamily={this.props.font.family}
        >
          <ThemeProvider theme={theme}>
            <AppLoading />
          </ThemeProvider>
        </FontStylesContainer>
      );
    } else if (this.state.licenseFetchingStatus == "fetching") {
      return (
        <FontStylesContainer
          fontSize={this.props.font.size}
          fontFamily={this.props.font.family}
        >
          <ThemeProvider theme={theme}>
            <TermsLoading text="Please Wait While We Gather Your License Info" />
          </ThemeProvider>
        </FontStylesContainer>
      );
    } else if (this.state.licenseFetchingStatus == "error") {
      return (
        <FontStylesContainer
          fontSize={this.props.font.size}
          fontFamily={this.props.font.family}
        >
          <ThemeProvider theme={theme}>
            <Header
              logout={this.logout}
              handleGraphClick={this.handleGraphClick}
            />

            <LicenseError></LicenseError>
          </ThemeProvider>
        </FontStylesContainer>
      );
    } 
    else if (this.props.user.licenseType == "NONE") {
      return (
        <FontStylesContainer
          fontSize={this.props.font.size}
          fontFamily={this.props.font.family}
        >
          <ThemeProvider theme={theme}>
            <React.Fragment>
              <Normalize />
              <BaseStyles />
              <AppStylesContainer
                fontSize={this.props.font.size}
                fontFamily={this.props.font.family}
              >
                <Header
                  logout={this.logout}
                  handleGraphClick={this.handleGraphClick}
                />
                <NoLicense environment_conf={environment_conf}></NoLicense>
                <Route
                  path="/clio"
                  environment_conf={environment_conf}
                  component={ClioScreen}
                />

                <BottomBar />
              </AppStylesContainer>
            </React.Fragment>
          </ThemeProvider>
        </FontStylesContainer>
      );
    } 
    else if (!this.props.analysis) {
      return (
        <FontStylesContainer
          fontSize={this.props.font.size}
          fontFamily={this.props.font.family}
        >
          <ThemeProvider theme={theme}>
            <Header
              logout={this.logout}
              handleGraphClick={this.handleGraphClick}
            />
            <Analyzing></Analyzing>
          </ThemeProvider>
        </FontStylesContainer>
      );
    } 
    else {
      return (
        <FontStylesContainer
          fontSize={this.props.font.size}
          fontFamily={this.props.font.family}
        >
          <ThemeProvider theme={theme}>
            <React.Fragment>
              <Normalize />
              <BaseStyles />
              <AppStylesContainer
                fontSize={this.props.font.size}
                fontFamily={this.props.font.family}
              >
                <Header
                  logout={this.logout}
                  handleGraphClick={this.handleGraphClick}
                />
{/* 
                <DefaultButton
                  text={this.getMode() === "read" ? "Write" : "Read"}
                  onClick={() => {
                    if (this.getMode() === "read")
                      this.props.history.push("/writer");
                    else {
                      this.props.history.push("/");
                    }
                  }}
                /> */}

                {/* <Route
                  exact
                  path="/"
                  component={
                    this.props.user.analysisEngine == "AI"
                      ? Clauses
                      : TermsScreen
                  }
                /> */}
                <Route exact path="/" component={HomeScreen} />
                <Route path="/writer" component={Writer} key={uuidv4()} />
                <Route path="/settings" component={SettingsScreen} />
                <Route path="/experimental" component={ExperimentalScreen} />
                <Route path="/term/:id" exact component={TermScreen} />
                <Route path="/term/not-found/:term" component={TermNotFound} />
                <Route
                  path="/clio"
                  component={() => (
                    <ClioScreen environment_conf={environment_conf} />
                  )}
                />

                <BottomBar
                  refreshClauses={this.refreshClauses}
                  clauses={this.props.analysis.clauses}
                  mode={this.getMode()}
                />
              </AppStylesContainer>
            </React.Fragment>
          </ThemeProvider>
        </FontStylesContainer>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  font: state.font,
  user: state.user,
  analysis: state.terms ? state.terms.analysis : undefined,
  isGraphDialog: state.application.isGraphDialog,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user: IUser) => dispatch(setUser(user)),
  setTerms: () => dispatch(setTerms()),
  setLicense: (license: LICENSE_TYPES) => dispatch(setLicenseType(license)),
  setAdmin: (admin: boolean) => dispatch(setAdmin(admin)),
  setAccessToken: (accessToken: string) =>
    dispatch(setAccessToken(accessToken)),
  setSubscriptionOwner: (owner: boolean) =>
    dispatch(setSubscriptionOwner(owner)),
  setAnalysis: (analysis: any) => dispatch(setAnalysis(analysis)),
  setIsGraphDialog: (isGraphDialog: any) =>
    dispatch(setIsGraphDialog(isGraphDialog)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
