import * as React from 'react';
import styled from 'styled-components';
import { FaCog } from 'react-icons/fa';
import { Settings } from 'react-feather';
import Particles from 'react-particles-js';

import { Container } from './styled';

interface ITermsLoading {
  text: string;
}

const TermsLoading: React.StatelessComponent<ITermsLoading> = ({ text }) => (
  <Container>
    <Particles
      height="calc(100vh - 150px)"
      width="100vw"
      params={{
        "particles": {
          "color": {
            "value": '#0078d4',
          },
          "number": {
            'value': 75,
          },
          "line_linked": {
            "color": "#0078d4",
            "opacity": .2,
          },
          "move": {
            "speed": 2,
          }
        },
      }}
    />
    <p className="loading-text">{text}</p>
  </Container>
);

export default TermsLoading;
