import { modelType } from "./types";

export const SELECTED_TEXT_CHARACTER_LIMIT = 10000;

export const providers = [
  {
    name: "OpenAI",
    value: "openai",
  },
  {
    name: "Anthropic",
    value: "anthropic",
  },
  {
    name: "TogetherAI",
    value: "togetherai",
  },
];

export const openAiModels: modelType[] = [
  {
    name: "GPT-4o",
    value: "gpt-4o",
  },
  {
    name: "GPT-4o mini",
    value: "gpt-4o-mini",
  },
];

export const togetherModels: modelType[] = [
  {
    name: "mistralai/Mixtral-8x7B-Instruct-v0.1",
    value: "mistralai/Mixtral-8x7B-Instruct-v0.1",
  },
  {
    name: "mistralai/Mistral-7B-Instruct-v0.1",
    value: "mistralai/Mistral-7B-Instruct-v0.1",
  },
  {
    name: "togethercomputer/CodeLlama-34b-Instruct",
    value: "togethercomputer/CodeLlama-34b-Instruct",
  },
];

export const anthropicModels: modelType[] = [
  {
    name: "claude-3-sonnet-20240229",
    value: "claude-3-sonnet-20240229",
  },
  {
    name: "claude-2.1",
    value: "claude-2.1",
  },
];
