import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  getSelectedText,
  removeTextAndAddNewContent,
} from "../../actions/prompt";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { ITerm } from "../../interfaces/ITermsInterface";
import { User } from "../../interfaces/IUserInterface";
import { IMessage } from "../../../utilities/types";
import { SELECTED_TEXT_CHARACTER_LIMIT } from "../../../utilities/constants";
import TermLink from "../../components/TermLink";
import JsxParser from "react-jsx-parser";
import TermModal from "../../../utilities/TermModal";
import { environment_conf } from "../../../utilities/environment";
import Redline from "../../components/WriterSearch/Redline";
import { getTermsWords, ingestDoc } from "../../../utilities/utils";

function capitalizeFirstLetters(text: string): string {
  return text
      .split(' ') // Split the text into words by spaces
      .map(word => 
          word ? word[0].toUpperCase() + word.slice(1).toLowerCase() : word
      ) // Capitalize the first letter of each word, and ensure the rest are lowercase
      .join(' '); // Join the words back into a single string
}

export const hyperlinkTermsAndSections = (
  text: string,
  terms: ITerm[],
  sections: ITerm[],
  setCurrentTerm: (string) => void
) => {
  let elements = [];
  
  // Split the text into parts that are not HTML tags or entities
  let parts = text.split(/(<[^>]+>|&[^;]+;)/);

  // console.log(parts);

  // Combine and sort terms and sections by length in descending order
  let items = terms.concat(sections).sort((a, b) => b.word.length - a.word.length);

  parts.forEach((part) => {
    if (/<[^>]+>|&[^;]+;/.test(part)) {
      // If part is an HTML tag or entity, push it as is
      elements.push(part);
    } else {
      items.forEach((item) => {
        console.log(item)
        // Create a regex to match the exact word, avoiding partial matches inside already replaced text
        const regex = getTermRegex(item);
        // console.log(regex)
        part = part.replace(regex, (match) => {
          // Ensure that the match is not already within a TermLink tag
          if (!part.includes(`<TermLink`) || !new RegExp(`<TermLink[^>]*>${match}<\\/TermLink>`).test(part)) {
            let word = capitalizeFirstLetters(match);
            // console.log(`>>>>>>>>>> The term/section is: ${word}`);
            return `<TermLink setCurrentTerm={setCurrentTerm} termId="${item.id}">${word}</TermLink>`;
          }
          return match; // Return the original match if it's already inside a TermLink
        });
      });
      elements.push(part);
    }
  });

  const finalJsx = elements.join("");

  // Log the final JSX string for debugging
  console.log("Final JSX String:", finalJsx);

  // Use JSXParser to convert array of JSX elements and strings to a single JSX element
  return (
    <JsxParser
      bindings={{ setCurrentTerm: (term: ITerm) => setCurrentTerm(term) }}
      jsx={finalJsx}
      components={{ TermLink }}
    />
  );
};

// Helper function similar to getTermRegex from TermScreen
function getTermRegex(term) {
  const escapedWord = term.word.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  return new RegExp(`\\b${escapedWord}\\b`, "gi");
}

type Props = {
  terms: ITerm[];
  sections: ITerm[];
  others: ITerm[];
  analysis: any;
  user: User;
  namespace: string;
  messages: IMessage[];
  setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>;
  aiProvider: string;
  aiModel: string;
  aiApiKey: string;
  userId: string;
};

type Compare = {
  suggestedText: string;
  originalText: string;
};

const Prompt = ({
  // terms,
  // sections,
  analysis,
  user,
  namespace,
  others,
  aiApiKey,
  aiModel,
  aiProvider,
  userId,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [insertingDocument, setInsertingDocument] = useState(false);
  const [considerations, setConsiderations] = useState<string[]>([]);

  const [currentTerm, setCurrentTerm] = useState<string>();

  const [compare, setCompare] = useState<Compare>(null);

  const [messages, setMessages] = useState<IMessage[]>([]);

  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (messages.length != 0) {
      localStorage.setItem("message_history", JSON.stringify(messages))
    }
  }, [messages]);

  useEffect(() => {
     // Load message history
     const message_history = localStorage.getItem("message_history")

     if (message_history) {
       const x = JSON.parse(message_history)
 
         const stored_message = x.map((element: any) => {{
           return {
             username: element.username,
             content: element.content,
             renderContent: hyperlinkTermsAndSections(
              element.content,
              terms,
              sections,
              setCurrentTerm
            ),
             created: element.created,
             type: element.type,
           }
         }})
   
         setMessages(stored_message)
     }
  }, [])

  const terms = analysis.clauses.filter((r) => r.clauseType === "TERM");
  const sections = analysis.clauses.filter((r) => r.clauseType === "SECTION");

  const promptTextarea = useRef<HTMLTextAreaElement>(null);

  function isTextAreaEmpty() {
    return !promptTextarea?.current || promptTextarea.current.value.trim() === "";
  }

  const openAiPrompt = async () => {
    setLoading(true);
    setConsiderations([]);

    const selectedText = await getSelectedText();

    var prompt = promptTextarea.current.value;

    if (selectedText.length > SELECTED_TEXT_CHARACTER_LIMIT) {
      toast.error(
        "Please select less than 10000 characters at a time to allow us to best process your messages",
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
      setLoading(false);
      return;
    }

    var question = ""

    if (isTextAreaEmpty() && selectedText.trim() != "") {
      // User has selected a text but haven't provided any input
      prompt = "What does the highlighted text mean?"
      question = `What does the highlighted text mean: #${selectedText}# `
    } else if (!isTextAreaEmpty() && selectedText.trim() != "") {
      // User has selected some text and is asking a question regarding that
      question = `${prompt} Please use the following information about the contract to aid your response: #${selectedText}#`
    } else if (isTextAreaEmpty() && selectedText.trim() == "") {
      // User has not selected nor is asking any question
      toast.error("Please input some messages.", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setLoading(false);
      return;
    } else {
      // User is asking a question
      question = prompt
    }

    setMessages((current) => [
      ...current,
      {
        username: user.name === "" ? "You" : user.name,
        content: prompt,
        created: new Date().toISOString(),
        type: "user",
      },
    ]);
    promptTextarea.current.value = "";
    promptTextarea.current.style.height = "auto";

    try {
      const allTermsTitle = await getTermsWords()

      console.log(">>>>>>>>>>> X:STARTED")

      const response = await axios.post<{
        output: string;
        consideratons: string[];
      }>(`${environment_conf.base_llm_domain}/query`, {
        question: question,
        apiKey: aiApiKey,
        modelName: aiModel,
        provider: aiProvider,
        messages: messages.map((m) => ({ type: m.type, content: m.content })),
        terms: allTermsTitle,
        namespace: namespace,
      });

      console.log(">>>>>>>>>>> X:API CALL ENDED")

      // const response = await axios.post<{
      //   output: string;
      //   considerations: string[];
      // }>(`${environment_conf.base_llm_domain}/query`, {
      //   question: prompt,
      //   apiKey: 'sk-proj-0AYgSTLxO4GFNOwTqnbMT3BlbkFJ1dZyx1UTYw6ALtQ9SQJn',
      //   modelName: 'gpt-4o',
      //   provider: 'openai',
      //   messages: [],
      //   namespace: "haseeb",
      // });
      

      const langchainOutput = response.data.output;

      console.log(">>>>>>>>>>> X:LANGCHAIN OUTPUT: " + langchainOutput)

      // if (response.data.considerations.length)
      //   setConsiderations(considerations);
      // const revisedContent = langchainOutput.replace(/\n/g, "");
      const revisedContent = langchainOutput;
      const renderContent = hyperlinkTermsAndSections(
        revisedContent,
        terms,
        sections,
        setCurrentTerm
      );
      console.log(">>>>>>>>>>> X:HYPER LINK DONE")
      setMessages((current) => [
        ...current,
        {
          username: "Spectacles",
          content: revisedContent,
          renderContent,
          created: new Date().toISOString(),
          type: "assistant",
        },
      ]);


      console.log(">>>>>>>>>>> X:MESSAGE SET")
    } catch (error) {
      toast.error(`Error caught in the main try bloc: ${error}`)
      
      console.log(">>>>>>> QUERY LOG");
console.log(JSON.stringify({
  question: question,
  apiKey: aiApiKey,
  modelName: aiModel,
  provider: aiProvider,
  messages: messages.map((m) => ({ type: m.type, content: m.content })),
  namespace: namespace,
}, null, 2));

      setMessages((current) => {
        const newMessages = [...current];
        newMessages.pop();
        return newMessages;
      });
      if (axios.isAxiosError(error)) {
        const { status, data } = error.response;
        console.log(status, data);
        if (status !== 500) {
          const message = data.message;
          toast.error(message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } else {
          toast.error("Something went wrong. Please check again", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      } else {
        console.error(error);
        toast.error("Something went wrong. Please check again", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const autosizeTextarea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = event.target;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  };

  const onInsert = async (content: string) => {
    // await applyStrikethrough();
    // await insertContentNextSelected(content);
    await removeTextAndAddNewContent(content);
  };

  const onCompare = async (aiOutput: string) => {
    const selectedText = await getSelectedText();

    if (!selectedText) {
      toast.error("Please select some text to compare against");
      return;
    }

    setCompare({
      originalText: selectedText,
      suggestedText: aiOutput,
    });
  };

  const onSubmit = async () => {
    setInsertingDocument(true);

    setMessages([])

    // Clearing chat history
    localStorage.removeItem("message_history")

    try {
      await ingestDoc(userId, aiApiKey);
      console.log(">>>>>>>> DATA INGESTED | LOG TEST")
      toast.info("Data Ingested, start chatting away!");
    } catch (error) {
      toast.error(error)
      console.log(error);
    } finally {
      setInsertingDocument(false);
    }
  };

  return (
    <div className="Ai-Vision">
      <button 
        type="button" 
        onClick={() => onSubmit()} 
        style={{
            marginTop: '20px',
            backgroundColor: '#0078d4',
            borderRadius: '20px',
            borderColor: '#0078d4',
            color: 'white',
            cursor: 'pointer',
            height: '40px'
    }}
    >
        {insertingDocument ? `Ingesting...` :`Ingest Doc`}
    </button>


      {messages.length ? (
        <div className="messages">
          {messages.map((message, index) => {
            return (
              <div
                className="message"
                key={index}
                ref={index === messages.length - 1 ? messagesEndRef : null}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: message.type === "user" ? "right" : "left",
                    marginBottom: "8px",
                  }}
                >
                  {message.type === "assistant" ? (
                    <span style={{ marginRight: "10px" }}>
                      <img
                        width="32"
                        height="32"
                        src="/assets/logo-filled.png"
                        alt="Spectacles Logo"
                      />
                    </span>
                  ) : (
                    <strong className="username">{message.username}</strong>
                  )}
                  <p style={{ font: "bold" }}>
                    {new Intl.DateTimeFormat("default", {
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(new Date(message.created))}
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor:
                      message.type === "user"
                        ? "rgb(0, 120, 212)"
                        : "whitesmoke",
                    color: message.type === "user" && "white",
                  }}
                  className="message-content"
                >
                  {message.renderContent ?? <p>{message.content}</p>}
                </div>
                {message.username === "Spectacles" && (
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "8px" }} className="insertBtn">
                      <button onClick={() => onInsert(message.content)}>
                        Insert
                      </button>
                    </div>
                    <div className="insertBtn">
                      <button onClick={() => onCompare(message.content)}>
                        Compare
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          {/* {considerations.length ? (
            <div className="message-considerations">
              <p>
                It seems as though the following new terms have been added in
                the AIs response:
              </p>
              {considerations.map((c) => (
                <p>{c}</p>
              ))}
              <button
                onClick={() => {
                  promptTextarea.current.value = `Create definitions for each of the new terms you just added to the recent output, "${considerations.join(
                    ", "
                  )}"`;
                  openAiPrompt(true);
                }}
              >
                Generate defintions for these new terms
              </button>
            </div>
          ) : null} */}
          {compare && (
            <div className="message-redline">
              <button onClick={() => setCompare(null)}>Close</button>
              <Redline
                originalText={compare.originalText}
                suggestedText={compare.suggestedText}
              />
            </div>
          )}
        </div>
      ) : null}

      <div className="grow-wrap">
        <textarea
          ref={promptTextarea}
          name="text"
          id="text"
          onInput={autosizeTextarea}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault(); // Prevents the default action of inserting a newline
              openAiPrompt();
            }
          }}
          rows={1}
          placeholder={loading ? "Thinking..." : "Ask Spectacles"}
        />
        <button
          className="ms-Button"
          id="revise"
          onClick={() => openAiPrompt()}
        >
          <span>
            <img
              width="24"
              height="24"
              src="/assets/spectacles-monocles.png"
              alt="Spectacles Monocles Logo"
            />
          </span>
        </button>
      </div>
      {currentTerm && (
        <TermModal id={currentTerm} setCurrentTerm={setCurrentTerm} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  analysis: state.terms.analysis,
});

export default connect(mapStateToProps)(Prompt);
