import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import "./style.css";
import { updateUserAiSetting } from "../../../utilities/server";
import { IUser, User } from "../../interfaces/IUserInterface";
import { setUser } from "../../actions/user";
import { toast } from "react-toastify";
import {
  togetherModels,
  openAiModels,
  providers,
  anthropicModels,
} from "../../../utilities/constants";
import { modelType } from "../../../utilities/types";
import { ITerm } from "../../interfaces/ITermsInterface";

type modelNames = "anthropic" | "openai" | "togetherai";

type Props = {
  terms: ITerm[];
  sections: ITerm[];
  user: User;
  setUser: (user: IUser) => any;
  userId: string;
  provider: modelNames;
  model: string;
  organId: string;
  aikey: string;
};

export const AiSettings = ({
  user,
  setUser,
  userId,
  provider,
  model,
  organId,
  aikey,
}: Props) => {
  const [aiProvider, setAiProvider] = useState<modelNames>();
  const [aiModel, setAiModel] = useState<string>();
  const [organisationId, setOrganisationId] = useState<string>();
  const [apiKey, setApiKey] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const updateChanges = async () => {
    console.log({
      aiProvider,
      aiModel,
      organisationId,
      apiKey,
    });
    const res = await updateUserAiSetting(
      user.accessToken,
      aiProvider,
      aiModel,
      organisationId,
      apiKey
    );
    if (res) {
      setUserWithAi();
      toast.success("Keys Updated!", {
        toastId: "keys-update-success",
        position: toast.POSITION.BOTTOM_LEFT,
      });
      console.log("success");
    } else {
      toast.error("Error occured", {
        toastId: "keys-update-error",
        position: toast.POSITION.BOTTOM_LEFT,
      });
      console.log("error");
    }
  };

  const setUserWithAi = async () => {
    const newUser = {
      ...user,
      aiProvider,
      aiModel,
      aiOrganisationId: organisationId,
      aiApiKey: apiKey,
    };
    console.log(newUser, "newUser");

    setUser(newUser);
  };

  useEffect(() => {
    setAiProvider(provider);
    setAiModel(model);
    setOrganisationId(organId);
    setApiKey(aikey);
  }, [provider, model, organId, aikey]);

  const models: modelType[] = useMemo(() => {
    if (aiProvider === "togetherai") return togetherModels;
    if (aiProvider === "openai") return openAiModels;
    if (aiProvider === "anthropic") return anthropicModels;
    return [];
  }, [aiProvider]);

  return (
    <div className="Ai-Settings">
      <div>
        <select
          name="provider"
          id="provider"
          onChange={(e) => {
            setAiProvider(e.target.value as modelNames);
            setApiKey("");
            setOrganisationId("");
          }}
          value={aiProvider}
        >
          <option value={null}>Please Select AI Provider</option>
          {providers.map((provider) => (
            <option key={provider.value} value={provider.value}>
              {provider.name}
            </option>
          ))}
        </select>
        {!aiProvider && (
          <span className="error">{"You have to select AI provider."}</span>
        )}
      </div>
      <div>
        <select
          name="model"
          id="model"
          onChange={(e) => setAiModel(e.target.value)}
          value={aiModel}
        >
          <option value={null}>Please Select AI Model</option>
          {models.map((model) => (
            <option key={model.value} value={model.value}>
              {model.name}
            </option>
          ))}
        </select>
        {!aiModel && (
          <span className="error">{"You have to select AI model."}</span>
        )}
      </div>
      <div>
        <input
          type="text"
          name="organisationId"
          id="organisationId"
          placeholder="Organisation Id"
          defaultValue={organisationId}
          onChange={(e) => setOrganisationId(e.target.value)}
        />
        {!organisationId && (
          <span className="error">{"You have to input Organisation Id."}</span>
        )}
      </div>
      <div>
        <input
          type="text"
          name="apiKey"
          id="apiKey"
          placeholder="API Key"
          defaultValue={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
        />
        {!apiKey && (
          <span className="error">{"You have to input API key."}</span>
        )}
      </div>
      {/* <div>
        {loading && <p>Loading....</p>}
        <button type="button" className="updateBtn" onClick={onSubmit}>
          Ingest Doc
        </button>
      </div> */}
      <div>
        <button type="button" className="updateBtn" onClick={updateChanges}>
          Update Changes
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user: IUser) => dispatch(setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AiSettings);
