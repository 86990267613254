import axios from "axios";
import { LICENSE_TYPES } from "../src/interfaces/IUserInterface";
import { environment_conf as conf } from "./environment";

export const getDomainHint = (emailAddress, callback, err) => {
  axios
    .get(conf.base_app_domain + `/domainHints/get?email=${emailAddress}`)
    .then((resp) => callback(resp))
    .catch((error) => err(error));
};

export const getLicense = async (
  accessKey,
  setState,
  getTerms,
  setLicenseType,
  setAdmin,
  setSubscriptionOwner,
  environment_conf
) => {
  var baseDomain = environment_conf.base_app_domain;


  const config = {
    headers: { Authorization: "Bearer " + accessKey },
  };

  setState({ licenseFetchingStatus: "fetching" });

  axios
    .get(baseDomain + "/seats/getSeat", config)
    .then(function (response) {
      setState({ licenseFetchingStatus: "fetched" });

      if (!response.data || response.data.status != "Subscribed") {
        setLicenseType(LICENSE_TYPES.NONE);
        return;
      }

      var plan = response.data.planId.toLowerCase();
      switch (plan) {
        case "basic":
          setLicenseType(LICENSE_TYPES.BASIC);
          setSubscriptionOwner(response.data.subscriptionOwner);
          setAdmin(false);
          break;
        case "premium":
          setLicenseType(LICENSE_TYPES.PREMIUM);
          setSubscriptionOwner(response.data.subscriptionOwner);
          setAdmin(false);
          break;
        case "admin":
          setLicenseType(LICENSE_TYPES.BASIC);
          setSubscriptionOwner(response.data.subscriptionOwner);
          setAdmin(true);
          break;
        default:
          break;
      }

      getTerms();
    })
    .catch(function () {
      setState({ licenseFetchingStatus: "error" });
    });
};

export const getClioDocs = async (
  accessKey,
  parentId,
  environment_conf,
  callback
) => {
  var baseDomain = environment_conf.base_app_domain;

  const config = {
    headers: { Authorization: "Bearer " + accessKey },
  };

  axios
    .get(baseDomain + "/clio/App_Get?parentId=" + parentId, config)
    .then(function (response) {
      callback(response);
    });
};

export const getClioDoc = async (
  accessKey,
  docId,
  environment_conf,
  callback
) => {
  var baseDomain = environment_conf.base_app_domain;

  const config = {
    headers: { Authorization: "Bearer " + accessKey },
  };

  axios
    .get(baseDomain + "/clio/App_Document?documentId=" + docId, config)
    .then(function (response) {
      callback(response);
    });
};


export const updateUserAiSetting = async (
  tokenKey: string,
  aiProvider: string,
  aiModel: string,
  organisationId: string,
  apiKey: string
) => {
  var baseDomain = conf.base_app_domain;

  const config = {
    headers: { Authorization: "Bearer " + tokenKey },
  };

  const { status, data } = await axios.post(
    baseDomain + "/Account/Update_AiSetting",
    { aiProvider, aiModel, organisationId, apiKey },
    config
  );
  if (status) {
    return true;
  } else {
    return false;
  }
};

export const getUserAiSetting = async (tokenKey: string) => {
  var baseDomain = conf.base_app_domain;

  const config = {
    headers: { Authorization: "Bearer " + tokenKey },
  };

  const { status, data } = await axios.get(
    baseDomain + "/Account/Get_AiSetting",
    config
  );
  if (status) {
    return data;
  } else {
    return null;
  }
};
